import { useMutation } from "@apollo/client";
import { useState } from "react";
import CityDropdownFilter from "../components/city-dropdown-filter";
import DriverCard from "../components/driver-card";
import Loading from "../components/loading";
import DashboardProvider from "../providers/dashboard.provider";
import { UPDATE_FRIDGES } from "../services/organization.service";
import { GET_DRIVERS, useDrivers } from "../services/user.service";

export default function AdminHome() {
  const [cityChanged, setCityChanged] = useState<string>("City");
  const cities = ["City", "YXE", "YYC", "YQR"];
  const { drivers: currDrivers, loading: driversLoading } =
    useDrivers(cityChanged);

  const [updateFridge] = useMutation(UPDATE_FRIDGES);

  async function handleUpdateDriver({
    driverIds,
    fridgeIds,
  }: {
    driverIds: number[];
    fridgeIds: number[];
  }) {
    try {
      await updateFridge({
        variables: {
          input: { driverIds, fridgeIds },
        },
        refetchQueries: [
          {
            query: GET_DRIVERS,
            variables: { city: cityChanged },
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <DashboardProvider>
      <div className="px-4 pb-4">
        <header>
          <div className="flex flex-row py-10 mx-auto max-w-7xl">
            <CityDropdownFilter
              title={cityChanged}
              changed={cityChanged !== "City" ? true : false}
              items={cities}
              width="w-24"
              widthOfDropdown="w-56"
              functionToSend={setCityChanged}
              customMenuTitle="All Locations"
            />
            <div className="pl-3" />
          </div>
        </header>
        <main className="mx-auto max-w-7xl">
          {driversLoading ? (
            <Loading />
          ) : (
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {currDrivers?.map((driver) => (
                <DriverCard
                  driver={driver}
                  key={driver.id}
                  handleUpdateDriver={handleUpdateDriver}
                  city={cityChanged}
                />
              ))}
            </div>
          )}
        </main>
      </div>
    </DashboardProvider>
  );
}
