import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

class SearchService {
  private static readonly STORAGE_KEY = "search";

  static saveSearch(query: string): void {
    sessionStorage.setItem(this.STORAGE_KEY, query);
  }

  static getValue(): string | null {
    return sessionStorage.getItem(this.STORAGE_KEY);
  }

  static clearValue(): void {
    sessionStorage.removeItem(this.STORAGE_KEY);
  }
}

interface SearchContextType {
  search: string | null;
  setSearch: (query: string) => void;
  handleClearSearch: () => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

interface SearchProviderProps {
  children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    const storedSearch = SearchService.getValue();
    if (storedSearch) {
      setSearch(storedSearch);
    }
  }, []);

  const handleSetSearch = (newSearch: string) => {
    SearchService.saveSearch(newSearch);
    setSearch(newSearch);
  };

  const handleClearSearch = () => {
    SearchService.clearValue();
    setSearch(null);
  };

  return (
    <SearchContext.Provider
      value={{ search, setSearch: handleSetSearch, handleClearSearch }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
