import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AuthProvider from "./providers/auth.provider";
import { GraphQLProvider } from "./providers";
import RouteProvider from "./providers/route.provider";
import SmartlookProvider from "./providers/smartlook.provider";
import reportWebVitals from "./reportWebVitals";
import NetworkProvider from "./providers/network.provider";
import { SearchProvider } from "./providers/search.provider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GraphQLProvider>
      <AuthProvider>
        <SmartlookProvider>
          <NetworkProvider>
            <SearchProvider>
              <RouteProvider />
            </SearchProvider>
          </NetworkProvider>
        </SmartlookProvider>
      </AuthProvider>
    </GraphQLProvider>
  </React.StrictMode>
);

reportWebVitals();
