import { gql, useQuery } from "@apollo/client";
import { Fridge, QueryResultPagination } from "../types";
import getNodeDataFromEdges from "../utils/get-node-data-from-edges";
import { QueryResult } from "../types";

export const LOCATIONS = gql`
  query driverFridges(
    $city: String
    $drivers: [Int]
    $search: String
    $first: Int
    $after: String
  ) {
    driverFridges(
      city: $city
      drivers: $drivers
      search: $search
      first: $first
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          isOnline
          location {
            city
          }
          drivers {
            edges {
              node {
                id
                lastName
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const FRIDGE_BY_ID = gql`
  query fridge($id: Int) {
    fridge(id: $id) {
      isOnline
      location {
        city
      }
      deliveryNotes
      cubbiSet {
        edges {
          node {
            isAvailable
            position
            use
            communal
            id
            groupOrder
          }
        }
      }
    }
  }
`;

export function useFridgeData(id: number | string | undefined) {
  const { data, loading, error, refetch } = useQuery<{
    fridge: Fridge;
  }>(FRIDGE_BY_ID, {
    variables: { id },
    fetchPolicy: "network-only",
    skip: !id,
  });

  return { data: data?.fridge, loading, error, refetch };
}

export function useLocations({
  selectedCity,
  selectedDriver,
  search,
  first = 20,
}: {
  selectedCity: string;
  selectedDriver: number | undefined;
  search: string | null | undefined;
  first?: number;
}) {
  const city =
    selectedCity === "City"
      ? undefined
      : selectedCity === "YXE"
      ? "Saskatoon"
      : selectedCity === "YQR"
      ? "Regina"
      : "Calgary";
  const drivers = selectedDriver ? [selectedDriver] : undefined;
  const { data, loading, error, refetch, networkStatus, fetchMore } = useQuery<{
    driverFridges: QueryResultPagination<Fridge>;
  }>(LOCATIONS, {
    variables: { city: city, drivers: drivers, search: search, first },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const fridges =
    data?.driverFridges.edges && getNodeDataFromEdges(data.driverFridges.edges);
  const hasNextPage = data?.driverFridges.pageInfo?.hasNextPage;
  const hasPreviousPage = data?.driverFridges.pageInfo?.hasPreviousPage;
  const endCursor = data?.driverFridges.pageInfo?.endCursor;
  return {
    fridges,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
    hasNextPage,
    hasPreviousPage,
    endCursor,
  };
}
