import { useEffect, useRef, useState } from "react";
import Loading from "../components/loading";
import LocationItems from "../components/location-item";
import DriverDashboardProvider from "../providers/driver-dashboard.provider";
import {
  updateFridgesQuery,
  useDriverFridges,
} from "../services/organization.service";
import { useMe } from "../services/user.service";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NetworkStatus } from "@apollo/client";
import { useSearch } from "../providers/search.provider";

export default function DriverHome() {
  const { me, refetch, loading: loadingMe } = useMe();
  const { search, setSearch } = useSearch();
  const [searchTerm, setSearchTerm] = useState("");

  const { fridges, loading, networkStatus, hasNextPage, fetchMore, endCursor } =
    useDriverFridges({
      drivers: me ? [me?.pk] : undefined,
      search: search,
      first: 20,
    });
  const [paginationLoading, setPaginationLoading] = useState(false);

  useEffect(() => {
    if (!me && !loadingMe) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, loadingMe]);

  useEffect(() => {
    if (search) setSearchTerm(search);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const ref = useRef<HTMLDivElement>(null);

  const onPaginate = async () => {
    if (
      fridges &&
      fridges.length > 0 &&
      networkStatus !== NetworkStatus.fetchMore &&
      hasNextPage &&
      !paginationLoading
    ) {
      setPaginationLoading(true);
      await fetchMore({
        updateQuery: updateFridgesQuery,
        variables: { after: endCursor },
      }).finally(() => setPaginationLoading(false));
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !loading) {
            onPaginate();
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, loading, networkStatus, hasNextPage, paginationLoading]);

  return (
    <DriverDashboardProvider>
      <div>
        <main>
          <div className="px-8 pt-4">
            <div className="-mb-8 ml-2">
              <FontAwesomeIcon icon={faSearch} className="text-gray-700" />
            </div>
            <input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              className="block w-full px-3 rounded-md border-0 bg-white py-2 text-black shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 sm:w-1/2 w-full pl-8"
            ></input>
          </div>
          <div style={{}} className="mx-auto p-8 grid grid-cols-1">
            {!me ? (
              <Loading />
            ) : (
              <>
                {networkStatus === NetworkStatus.loading ||
                networkStatus === NetworkStatus.setVariables ? (
                  <Loading />
                ) : fridges && fridges.length > 0 ? (
                  <>
                    <LocationItems fridges={fridges} />
                    <div ref={ref} className="pt-4 h-12">
                      {networkStatus === NetworkStatus.fetchMore && <Loading />}
                    </div>
                  </>
                ) : (
                  <div>No data</div>
                )}
              </>
            )}
          </div>
        </main>
      </div>
    </DriverDashboardProvider>
  );
}
