import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Driver } from "../types";
import DriverLocations from "./driver-locations";

interface Props {
  driver: Driver;
  handleUpdateDriver: ({
    driverIds,
    fridgeIds,
  }: {
    driverIds: number[];
    fridgeIds: number[];
  }) => Promise<void>;
  city: string;
}

export default function DriverCard({
  driver,
  handleUpdateDriver,
  city,
}: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [locationsAssigned, setLocationsAssigned] = useState<number[]>(
    driver.driverFridges.map(({ id }) => id)
  );

  const [expandedFridgeList, setExpandedFridgeList] = useState(false);

  function locationChanged(location: number, assigned: boolean) {
    if (assigned) {
      setLocationsAssigned([...locationsAssigned, location]);
    } else {
      setLocationsAssigned(locationsAssigned.filter((loc) => loc !== location));
    }
  }

  function onCancel() {
    setExpanded(false);
    setLocationsAssigned(driver.driverFridges.map(({ id }) => id));
  }

  async function onSave() {
    setLoading(true);
    await handleUpdateDriver({
      driverIds: [driver.pk],
      fridgeIds: locationsAssigned,
    });
    setLoading(false);
    setExpanded(false);
  }
  const fullName = `
    ${driver.firstName} ${driver.lastName ?? ""} (${
    driver.driverFridges.length
  })
  `;

  const driverFridges =
    driver && driver.driverFridges && driver.driverFridges.length > 0
      ? [...driver.driverFridges].sort((a, b) => a.name.localeCompare(b.name))
      : [];

  const handleExpandFridge = (e: any) => {
    e.stopPropagation();
    setExpandedFridgeList(!expandedFridgeList);
  };

  return (
    <div className="pb-2">
      <div className="flex flex-col bg-white rounded-md p-5">
        <div
          className="flex flex-row justify-between"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex flex-col gap-y-1">
            <div className="text-graphite-900 font-bold">{fullName}</div>
            <div className="text-graphite-700 text-sm">{driver.email}</div>
            <div
              className="text-primary-700 font-bold underline cursor-pointer text-sm"
              onClick={(e) => handleExpandFridge(e)}
            >
              {`${expandedFridgeList ? "Collapse" : "Expand"} user fridges`}
            </div>
          </div>

          <div>
            {expanded ? (
              <ChevronUpIcon width={20} height={20} color="#AFBDD8" />
            ) : (
              <ChevronDownIcon width={20} height={20} color="#AFBDD8" />
            )}
          </div>
        </div>
        {expandedFridgeList && (
          <div className="flex flex-row flex-wrap pt-2">
            {driver &&
            driver.driverFridges &&
            driver.driverFridges.length > 0 ? (
              driverFridges.map((fridge) => (
                <div
                  className="inline-flex text-sm bg-primary-600 rounded-md text-white py-1 px-2 mr-2 mb-2"
                  key={fridge.id}
                >
                  {fridge.name}
                </div>
              ))
            ) : (
              <div className="text-graphite-800 font-bold">
                No locations assigned
              </div>
            )}
          </div>
        )}
        {expanded && (
          <div className="">
            <div className="bg-white rounded-t-md mt-2 overflow-scroll h-[60vh]">
              <DriverLocations
                city={city}
                driver={driver}
                onChange={locationChanged}
              />
            </div>
            <div className="flex bg-white rounded-b-md justify-center w-full">
              <button
                className="py-2 border-graphite-300 border rounded-md text-sm text-graphite-700 font-medium w-full m-2"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                disabled={loading}
                className="bg-success-700 py-2 rounded-md text-sm text-white font-medium w-full m-2"
                onClick={onSave}
              >
                {loading ? "Loading" : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
