import { useState, useEffect } from "react";
import { Driver } from "../types";
import { useFridges } from "../services/organization.service";
import DriverLocation from "./driver-location-card";
import Spinner from "./loading";
interface Props {
  driver: Driver;
  onChange: (location: number, checked: boolean) => void;
  city: string;
}

export default function DriverLocations({ driver, onChange, city }: Props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const { fridges, loading: fridgesLoading } = useFridges({
    city:
      city === "City"
        ? undefined
        : city === "YXE"
        ? "Saskatoon"
        : city === "YQR"
        ? "Regina"
        : "Calgary",
    drivers: undefined,
    search,
  });

  return (
    <div>
      <input
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        className="block w-full px-3 rounded-md border-0 bg-white py-2 text-black shadow-sm border-gray-200 border-[1px] sm:text-sm sm:leading-6 w-full pl-4"
        placeholder="Search for pod"
      ></input>
      {fridgesLoading && (
        <div className="pt-4">
          <Spinner />
        </div>
      )}
      {fridges &&
        fridges.map((fridge) => {
          return (
            <DriverLocation
              driver={driver}
              fridge={fridge}
              onChange={onChange}
            />
          );
        })}
    </div>
  );
}
